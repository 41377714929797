/* Font Awesome */

@import '../fonts/fontawesome/css/fontawesome.min.css';
@import '../fonts/fontawesome/css/regular.min.css';

/* Montserrat Font */

// IMPORTANT relative is path is only ../ because webpack reads in the /styles directory

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-100.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-100.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-200.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-200.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-300.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-300.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-400.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-400.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-500.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-500.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-600.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-600.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-700.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-700.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-800.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-800.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-900.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-900.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-100-italic.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-100-italic.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-200-italic.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-200-italic.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-300-italic.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-300-italic.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-400-italic.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-400-italic.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-500-italic.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-500-italic.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-600-italic.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-600-italic.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-700-italic.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-700-italic.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-800-italic.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-800-italic.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat-900-italic.woff2') format('woff2 supports variations'),
	url('../fonts/montserrat-900-italic.woff2') format('woff2-variations');
	font-display: swap;
	font-weight: 900;
	font-style: italic;
}