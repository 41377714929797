@import '../helpers/media-queries';

.citymarks-location {
  position: relative;

  &__map {
    height: 610px;
    z-index: 1;
  }

  &__citymarks {
    @include md-up {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: 3;
    }
  }

  &__citymarks-bg {
    display: none;

    @include md-up {
      display: block;
      background: linear-gradient(90deg, white 0%, white 30%, transparent 100%);
      z-index: 2;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 66%;
      pointer-events: none;
    }
  }

  &__citymark {
    font-weight: 600;

    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid var(--color-gold);
    &:last-child {
      margin-bottom: unset;
      padding-bottom: unset;
      border-bottom: none;
    }
    @include md-down {
      margin-bottom: 20px !important;
      padding-bottom: 20px !important;

      &:last-child {
        padding-bottom: unset !important;
      }
    }

    &__icon {
      fill: var(--color-mint);
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }
  }
}
