@import '../helpers/vars';

.form-control {
  border-color: $clr-gray;
  border-radius: 8px;
  padding: 13px;
  font-size: calc($html-font-size - 2px);
  height: unset;

  &:focus,
  &.focus {
    border-color: $clr-gold;
    box-shadow: 0 0 0 0.2rem rgba(lighten($clr-gold, 10%), 0.75);
  }
}
