@import 'vars';
@import 'media-queries';
@import '../base/fonts';

// Theme settings for editor and frontend
@mixin website_style_guide {
  // Colors
  --color-bg: #{$clr-white};
  --color-dark: #{$clr-dark};
  --color-gold: #{$clr-gold};
  --color-mint: #{$clr-mint};
  --color-gray: #{$clr-gray};

  background-color: var(--color-bg);
  font-family: $font-family-primary;
  font-size: $html-font-size;
  line-height: 1.5;
  scroll-behavior: smooth;
}

@mixin breaktopints {
  --responsive-xxs: #{$responsive-xxs};
  --responsive-xs: #{$responsive-xs};
  --responsive-sm: #{$responsive-sm};
  --responsive-md: #{$responsive-md};
  --responsive-lg: #{$responsive-lg};
  --responsive-xl: #{$responsive-xl};
}

// Apply hover effects for supported devices only (prevent on touch screen)
@mixin mouseover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
