// Font families
$font-family-primary: 'Montserrat', 'sans-serif';

// Colors
$clr-white: white;
$clr-dark: #212121;
$clr-gold: #CFBB7C;
$clr-mint: #00C897;
$clr-gray: #E0DFDA;

// Sizes
$html-font-size: 16px;

// Transition
$transition-default: .15s ease;
