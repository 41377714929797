@import '../helpers/vars';

.navbar {
  .navbar-nav {
    .nav-item {
      font-size: calc($html-font-size - 2px);
      .nav-link {
        transition: $transition-default;
        font-weight: 500;
      }
      &.active .nav-link {
        color: $clr-gold;
        font-weight: 800;
      }
    }
  }

  &.navbar-light {
    background-color: $clr-white;
    color: $clr-dark;

    .nav-link {
      color: $clr-dark;
      &:hover {
        color: lighten($clr-dark, 30%);
      }
    }
  }

  &.navbar-dark {
    background-color: $clr-dark;
    color: $clr-white;

    .nav-link {
      color: $clr-white;
      &:hover {
        color: darken($clr-white, 30%);
      }
    }
  }
}
