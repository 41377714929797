@import './lib/splide-slider.scss';
@import './ui/navigation.scss';
@import './ui/btn.scss';
@import './ui/form-control.scss';
@import './ui/citymarks-location.scss';
@import './ui/header.scss';
@import './ui/dropdown.scss';
@import './helpers/index';
@import './base/framework';

// BEM Only syles in this file!!!

//TODO Revise this - Naviagtion styles
@media (min-width: 992px) {
  .dropdown-menu .dropdown-toggle:after {
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  .dropdown-menu .dropdown-menu {
    margin-left: 0;
    margin-right: 0;
  }
  .dropdown-menu li {
    position: relative;
  }
  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }
  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }
  .dropdown-menu > li:hover {
    background-color: #f1f1f1;
  }
  .dropdown-menu > li:hover > .submenu {
    display: block;
  }
}

// About The Project Section
.about-project {
  &__quote {
    font-size: calc($html-font-size + 4px);
    line-height: 28px;
    font-weight: 600;
  }
}

.benefits {
  &__benefit {
    & {
      border-right: 1px solid var(--color-gold);
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      position: static;

      @include xs-down {
        &:nth-of-type(2n) {
          border-right: none;
        }

        &:not(:last-child):nth-of-type(2n):before,
        &:not(:first-child):nth-of-type(2n):after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: var(--color-gold);
          position: absolute;
          left: 0;
        }
      }

      @include xs-to-md {
        &:nth-of-type(3n) {
          border-right: none;
        }

        &:not(:last-child):nth-of-type(3n):before,
        &:not(:first-child):nth-of-type(3n):after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: var(--color-gold);
          position: absolute;
          left: 0;
        }
      }

      @include md-up {
        &:nth-of-type(4n) {
          border-right: none;
        }

        &:not(:last-child):nth-of-type(4n):before,
        &:not(:first-child):nth-of-type(4n):after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: var(--color-gold);
          position: absolute;
          left: 0;
        }
      }
    }

    &__wrapper {
      padding-top: 15px;
      padding-bottom: 15px;
      height: 100%;
    }

    &__icon {
      fill: var(--color-mint);
      width: 30px;
      height: 30px;
      margin-bottom: 20px;
    }

    &__label {
      font-weight: 600;
      display: block;
      text-transform: uppercase;
    }
  }
}

// Perks Section
.perks {
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  &__wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;
    @include lg-up {
      margin-left: 3rem;
    }
    position: relative;
  }

  &__perk {
    & {
      border-right: 1px solid var(--color-gray);
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      position: static;

      @include md-down {
        &:nth-of-type(2n) {
          border-right: none;
        }

        &:not(:last-child):nth-of-type(2n):after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: var(--color-gray);
          position: absolute;
          left: 0;
        }
      }

      @include md-to-lg {
        &:nth-of-type(3n) {
          border-right: none;
        }

        &:not(:last-child):nth-of-type(3n):after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: var(--color-gray);
          position: absolute;
          left: 0;
        }
      }

      @include lg-up {
        &:nth-of-type(4n) {
          border-right: none;
        }

        &:not(:last-child):nth-of-type(4n):after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: var(--color-gray);
          position: absolute;
          left: 0;
        }
      }
    }

    &__wrapper {
      padding-top: 15px;
      padding-bottom: 15px;
      min-height: 200px;
    }

    &__icon {
      fill: var(--color-mint);
      width: 30px;
      height: 30px;
      margin-bottom: 20px;
    }

    &__label {
      font-weight: 600;
      display: block;
      text-transform: uppercase;
    }
  }
}

// CTA Buildings 1 & 2
.cta-buildings-1-2 {
  background-color: var(--color-gold);
  border-radius: 12px;
  overflow: hidden;

  &__img {
    height: 100%;
    object-fit: contain;
    border-radius: 12px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }

  @include md-down {
    background-color: unset;

    &__content {
      color: $clr-white;
    }
  }
}

// Conact Section
.contact-detail {
  display: flex;
  flex-direction: column;
  padding: 2rem;

  &__link,
  &__label {
    display: block;
    font-weight: 600;
  }

  &__label {
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  &__link {
    font-size: calc($html-font-size - 4px);
    color: $clr-dark;
    transition: $transition-default;

    &:hover {
      color: lighten($clr-dark, 30%);
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    fill: var(--color-mint);
    margin-bottom: 15px;
  }

  &:not(:first-child) {
    border-top: 1px solid var(--color-gold);

    @include sm-to-md {
      &:nth-child(2n)#{&}:not(:last-child) {
        border-top: unset;
      }
    }

    @include md-up {
      border-top: unset;
      border-left: 1px solid var(--color-gold);
    }
  }
}

// Gallery Section
.gallery {
  &__slide {
    &__image {
      max-width: 780px;
      width: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 0.5rem;

    @include sm-up {
      justify-content: center;
      flex-direction: row;
    }
  }

  &__filter {
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    border-radius: 999px;
    transition: $transition-default;

    svg {
      fill: var(--color-mint);
      width: 30px;
      height: 30px;
      transition: $transition-default;
    }

    &:hover {
      color: $clr-white;
      background-color: $clr-mint;

      svg {
        fill: $clr-white;
      }
    }
  }

  &__filter-label {
    margin-left: 0.5rem;
  }
}

// Location Section
.location {
  &__content {
    max-width: 860px;

    @include md-down {
      margin-bottom: 3rem;
    }
  }
}

// About us
.about-us {
  &__content {
    max-width: 718px;

    @include md-down {
      margin-bottom: 3rem;
    }
  }

  &__linder-logo {
    max-width: 332px;
    width: 100%;
    height: 50px;
  }
}

.stats {
  position: relative;
  @include sm-up {
    padding-bottom: 3rem;
  }
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent 0%,
      #212121 50%,
      transparent 100%
    );

    .dark-stats & {
      background: linear-gradient(
        90deg,
        transparent 0%,
        #fcfcfc 50%,
        transparent 100%
      );
    }
  }

  &__stat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @include sm-down {
      padding: 1rem;
    }

    &:not(:last-child):after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;

      height: 1px;
      width: 100%;
      background: linear-gradient(
        90deg,
        transparent 0%,
        #212121 50%,
        transparent 100%
      );

      @include sm-up {
        top: 0;
        bottom: unset;
        width: 1px;
        height: 100%;
        background: linear-gradient(
          0deg,
          transparent 0%,
          #212121 50%,
          transparent 100%
        );
      }
    }

    .dark-stats &:not(:last-child):after {
      background: linear-gradient(
        90deg,
        transparent 0%,
        #fcfcfc 50%,
        transparent 100%
      );

      @include sm-up {
        background: linear-gradient(
          0deg,
          transparent 0%,
          #fcfcfc 50%,
          transparent 100%
        );
      }
    }

    &__number {
      font-size: 42px;
      font-weight: 500;
    }
  }
}

.completed-projects {
  margin-top: 50px;
  margin-bottom: -100px;

  &__project {
    background-color: var(--color-gold);
    padding: 30px 20px;
    border-radius: 12px;
    color: var(--color-dark);

    @include md-down {
      text-align: center;
      padding: 5px 5px 30px 5px;
    }

    &__title {
      font-size: calc($html-font-size + 5px);
      font-weight: 600;
      position: relative;
      padding-top: 20px;
      padding-bottom: 20px;

      @include md-down {
        margin-bottom: 20px;
      }

      &:not(:last-child):after {
        content: '';
        position: absolute;
        background: linear-gradient(
          90deg,
          transparent 0%,
          #535353 50%,
          transparent 100%
        );

        @include md-down {
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 200px;
          height: 1px;
          background: linear-gradient(
            90deg,
            transparent 0%,
            #535353 50%,
            transparent 100%
          );
        }

        @include md-up {
          top: 0;
          bottom: 0;
          right: 0;
          width: 1px;
          background: linear-gradient(
            0deg,
            transparent 0%,
            #535353 50%,
            transparent 100%
          );
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;

      @include md-up {
        flex-direction: row;
      }

      align-items: center;
      font-size: calc($html-font-size - 4px);
      font-weight: 500;
      text-transform: uppercase;
    }

    &__logo {
      max-width: 122px;
      max-height: 82px;
      object-fit: contain;

      &--brightness-0 {
        filter: brightness(0);
      }

      &--grayscale-1 {
        filter: grayscale(1)
      }
    }
  }
}

.event {
  &__image {
    cursor: pointer;
    overflow: hidden;
    border-radius: 12px;
    position: relative;

    img {
      width: 100%;
      height: 278px;

      @include sm-up {
        height: 378px;
      }

      object-fit: cover;
      z-index: 1;
      transition: $transition-default;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($clr-dark, 0.3);
      z-index: 2;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transition: $transition-default;
    }

    &:hover {
      img {
        transform: scale(1.3);
      }

      &:before {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &__misc {
    display: flex;
    gap: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;

    svg {
      fill: var(--color-mint);
      width: 15px;
      height: 15px;
    }
  }

  &__icon-misc {
    display: flex;
    gap: 5px;
    font-weight: 600;
    font-size: calc($html-font-size - 4px);
  }

  &__title {
    font-weight: 600;
    text-transform: uppercase;
    font-size: calc($html-font-size + 4px);
    cursor: pointer;
    transition: $transition-default;

    &:hover {
      text-decoration: underline;
      color: lighten($clr-dark, 10%);
    }
  }

  .modal-dialog {
    max-width: 1010px;
  }

  .modal-content {
    border-radius: 12px;
  }

  .modal-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  .modal-close {
    padding: 0.5rem;
    position: absolute;
    top: 20px;
    right: 20px;
    line-height: 1;
  }
}

.lightbox {
  .modal-dialog {
    max-width: 100%;
  }
  .splide__arrow {
    pointer-events: all;
  }
  .splide__slide {
    justify-content: center;
  }
}

.modal-backdrop.show {
  opacity: 0.8;
}

.special-graphics {
  position: relative;

  &__content,
  &__wrapper {
    position: relative;
    z-index: 2;
  }

  &__image-contact {
    position: absolute;
    top: -45px;
    right: -10px;
  }

  &__bg-element-contact {
    fill: var(--color-mint);
    position: absolute;
    bottom: -10rem;
    left: calc(100% - 20rem);
    z-index: 1;
  }
}

.image-map {
  text-align: center;

  &__container {
    padding: 0.5rem;

    @include sm-up {
      padding: 1.5rem;
    }

    @include md-up {
      padding: 3.5rem;
    }

    max-width: 1500px;
    margin: auto;
  }

  &__maps {
    margin-top: 4rem;
    position: relative;
    overflow: hidden;
  }

  &__inner-maps,
  &__inner-map {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;

    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: all 0.6s ease-in-out;
    z-index: 0;

    &.active {
      pointer-events: all;
      opacity: 1;
      visibility: visible;
      z-index: 10;
    }
  }

  &__inner-back {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);

    border: 1px solid $clr-white;
    background-color: rgba($clr-white, 0.4);
    backdrop-filter: blur(20px);
    padding: 1rem;
    border-radius: 999px;
    transition: $transition-default;
    z-index: 20;

    @include sm-down {
      bottom: 1rem;
      padding: 0.7rem 0.75rem;
      line-height: 1;
    }

    & span {
      display: none;

      @include sm-up {
        display: inline-block;
      }
    }

    & svg {
      width: 14px;
      height: 14px;

      @include sm-up {
        margin-right: 0.5rem;
        width: 22px;
        height: 22px;
      }
    }

    &:hover {
      background-color: $clr-white;
    }
  }

  &__inner-details {
    position: absolute;

    &--building {
      top: 3rem;
      right: 3rem;

      @include sm-down {
        top: .5rem;
        right: .5rem;
      }
    }

    &--floor {
      top: 3rem;
      left: 3rem;

      @include sm-down {
        top: .5rem;
        left: .5rem;
      }
    }

    border: 1px solid $clr-gray;
    background-color: rgba($clr-white, 0.6);
    backdrop-filter: blur(20px);
    padding: 1rem;
    border-radius: 12px;
    transition: $transition-default;
    z-index: 20;
    
    min-width: 250px;

    @include sm-down {
      min-width: 50px;
    }

    color: $clr-dark !important;

    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: $transition-default;

    &.active {
      pointer-events: all;
      opacity: 1;
      visibility: visible;
    }
  }

  &__inner-details-title {
    @include sm-down {
      font-size: calc(100%);
    }
  }

  &__inner-details-desc {
    margin-bottom: 0;
    margin-top: 0.5rem;

    @include sm-down {
      font-size: calc(100% - 2px);
    }
  }

  &__inner-details-quadrature {
    // font-weight: 600;
    text-transform: uppercase;
  }

  &__inner-details-quadrature-icon {
    margin-right: 0.5rem;
    fill: $clr-mint;
    width: 33px;
    height: 33px;
  }

  &__heading {
    font-size: calc($html-font-size * 1.5);
    
    @include xs-up {
      font-size: calc($html-font-size * 2);
    }

    @include md-up {
      font-size: calc($html-font-size * 2.75);
    }

    @include lg-up {
      font-size: calc($html-font-size * 3.75);
    }
    font-weight: 400;
    margin-bottom: 30px;
    word-wrap: break-word;
    hyphens: auto;
  }

  &__subheading {
    font-size: calc($html-font-size + 2px);
    font-weight: 400;
  }
}

.mapster_tooltip {
  border: 1px solid $clr-white !important;
  background-color: rgba($clr-white, 0.4) !important;
  backdrop-filter: blur(20px);
  padding: 1rem !important;
  text-align: center;
  font-weight: 600;
}

.buildings {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @include sm-up {
    grid-template-columns: repeat(2, 1fr);
  }

  @include md-up {
    grid-template-columns: repeat(3, 1fr);
  }

  @include lg-up {
    grid-template-columns: repeat(4, 1fr);
  }

  gap: 1rem;
  position: relative;

  &__single {
    display: flex;
    flex-direction: column;
  }

  &__details-wrapper {
    min-height: 0;
    transition: all 0.6s ease;

    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: all 0.6s ease;
    transition-delay: 0.6s;

    .active & {
      pointer-events: all;
      visibility: visible;
      opacity: 1;
      transition-delay: 0.2s;
      min-height: 750px;
      @include md-up {
        min-height: 450px;
      }
    }
  }

  &__single:not(.active) &__details {
    display: none;
  }

  &__details {
    position: absolute;
    left: 15px;
    max-height: 750px;
    width: calc(100% - 2 * 15px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    @include md-up {
      flex-direction: row;
      max-height: 450px;
    }
    align-items: center;
    justify-content: center;
    gap: 3rem;
    height: 100%;
  }

  &__img-slider {
    max-width: 400px;
    border-radius: 12px;
    overflow: hidden;
  }

  &__icon {
    fill: $clr-mint;
    color: $clr-mint;
    width: 24px;
    height: 24px;
    font-size: 24px;
  }

  &__specs,
  &__single-perk {
    display: flex;
    column-gap: .5rem;
    align-items: end;
  }

  &__specs {
    margin: 1rem 0;
  }

  &__uppercase-span {
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
    font-size: 18px;
  }

  &__perks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
  }

  &__featured {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: end;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    min-height: 300px;
    padding: 1.5rem;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 140px;
      background: linear-gradient(transparent, rgba($clr-dark, 0.8));
      z-index: 2;

      .active & {
        height: 100%;
        background: rgba($clr-gold, 0.8);
      }
    }
  }

  &__featured-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  &__fetured-title,
  &__read-more {
    margin: 0;
    color: $clr-white;
    position: relative;
    z-index: 3;
  }

  &__read-more {
    text-decoration: underline;
    font-weight: 500;
    font-size: calc($html-font-size - 2px);
  }
}
