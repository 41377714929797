@import '../helpers/vars';
@import '../helpers/mixins';

:root {
  @include breaktopints();
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}

body {
  @include website_style_guide();
}

.heading-divider {
  font-weight: 400;
  margin-bottom: 25px;
  padding-top: 15px;
  position: relative;

  &::before {
    content: '';
    width: 58px;
    height: 3px;
    background-color: var(--color-mint);
    position: absolute;
    top: 0;
    left: 0;
  }
}

.titlebar {
  min-height: 450px;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: $clr-white;

  &__heading {
    font-size: calc($html-font-size * 2.5);

    @include sm-up {
      font-size: calc($html-font-size * 3.75);
    }

    font-weight: 400;
    margin-bottom: 78px;
  }

  &__subheading {
    text-transform: uppercase;
    font-weight: 400;

    &--big {
      font-size: calc($html-font-size * 1.5);

      @include sm-up {
        font-size: calc($html-font-size * 2);
      }
    }

    &--small {
      font-size: calc($html-font-size);

      @include sm-up {
        font-size: calc($html-font-size + 2px);
      }
    }

    word-wrap: break-word;
    hyphens: auto;
  }
}

.bs-section {
  overflow: hidden;

  &__img {
    &,
    & > img {
      width: 100%;
      border-radius: 12px;

      max-height: 350px;

      @include sm-up {
        max-height: unset;
      }
    }

    &--obj-cover {
      &,
      & > img {
        object-fit: cover;
      }
    }

    &--obj-contain {
      &,
      & > img {
        object-fit: contain;
      }
    }

    &--obj-fill {
      &,
      & > img {
        object-fit: fill;
      }
    }

    &--dots {
      position: relative;

      &:before {
        position: absolute;
        top: 0;
        left: 50px;
        transform: translateY(-50%);
        content: '';
        width: 71px;
        height: 18px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxOCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGVsbGlwc2UgY3g9IjkiIGN5PSI4LjUiIHJ4PSI4LjUiIHJ5PSI5IiB0cmFuc2Zvcm09InJvdGF0ZSgtOTAgOSA4LjUpIiBmaWxsPSIjQ0ZCQjdDIi8+Cjwvc3ZnPgo=);
        background-repeat: space;
      }
    }
  }

  &__divider {
    display: none;

    @include md-up {
      display: block;
      height: 266px;
      width: 2px;
      background-color: $clr-gray;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &--left {
        left: 0;
      }

      &--right {
        right: 0;
      }
    }
  }
}

.z-index-10 {
  z-index: 10;
}

.focus-none,
.focus-none:focus {
  outline: none !important;
}

$colors: (
  'brand-dark': $clr-dark,
  'brand-light': $clr-white,
  'gold': $clr-gold,
  'mint': $clr-mint,
);

// .bg-{color} function
@function generate-bg-classes($colors) {
  $classes: ();

  @each $name, $color in $colors {
    $class: unquote('.bg-#{$name}');
    $classes: map-merge(
      $classes,
      (
        $class: (
          background-color: $color,
        ),
      )
    );
  }

  @return $classes;
}

// Generate background color classes
$bg-classes: generate-bg-classes($colors);

// Output generated background color classes
@each $class, $properties in $bg-classes {
  #{$class} {
    @each $property, $value in $properties {
      #{$property}: $value;
    }
  }
}

// .text-{color} function
// Function to generate utility classes for text color with hover state
@function generate-text-color-utilities($colors) {
  $utilities: (); // Initialize an empty map

  @each $name, $color in $colors {
    $hover-color: lighten($color, 10%); // Lighten the color for hover state

    // Generate classes and store them in the map
    $utilities: map-merge(
      $utilities,
      (
        '.text-#{$name}': (
          color: $color,
          hover-color: $hover-color,
        ),
      )
    );
  }

  @return $utilities; // Return the map of utility classes
}

// Output the generated utility classes
$text-color-utilities: generate-text-color-utilities($colors);

@each $class, $colors in $text-color-utilities {
  #{$class} {
    color: map-get($colors, 'color');
    &:hover {
      color: map-get($colors, 'hover-color');
    }
  }
}

// .border-color-{color} function
@function generate-border-color-utilities($colors) {
  $utilities: ();

  @each $name, $color in $colors {
    $class-name: unquote('.border-color-#{$name}');

    $utilities: map-merge(
      $utilities,
      (
        #{$class-name}:
          (
            border-color: #{$color + ' !important'},
          )
      )
    );
  }

  @return $utilities;
}

$border-color-utilities: generate-border-color-utilities($colors);

@each $class, $properties in $border-color-utilities {
  #{$class} {
    @each $property, $value in $properties {
      #{$property}: $value;
    }
  }
}
