@import '../helpers/vars';

.btn {
  border-radius: 999px;
  font-weight: 600;
  padding: 11px 18px;
  &.btn-primary {
    background-color: $clr-gold;
    color: $clr-dark;
    border-color: $clr-gold;

    &:hover {
      background-color: lighten($clr-gold, 10%);
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      color: initial;
      background-color: lighten($clr-gold, 10%);
      border-color: $clr-gold;
    }
  }

  &.btn-secondary {
    background-color: $clr-white;
    color: $clr-dark;
    border-color: $clr-white;

    &:hover {
      background-color: darken($clr-white, 10%);
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      color: initial;
      background-color: darken($clr-white, 20%);
      border-color: $clr-white;
    }
  }

  &.btn-outline-secondary,
  &.btn-outline-secondary-mint {
    background-color: transparent;
    color: $clr-dark;
    border-color: $clr-mint;

    &:hover {
      background-color: $clr-mint;
      color: $clr-white;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      color: $clr-white;
      background-color: darken($clr-mint, 10%);
      border-color: $clr-mint;
    }
  }

  &.btn-outline-secondary-mint {
    color: $clr-mint;
  }

  &:not(.btn-icon) > svg {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    transition: $transition-default;
  }

  &:not(.btn-icon):hover {
    & > svg {
      margin-left: 15px;
    }
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(lighten($clr-mint, 10%), 0.25);
  }
}
