// @import '../helpers/vars';
@import '@splidejs/splide/css';

.splide__pagination__page {
  background: transparent;
  border: 2px solid var(--color-dark);
  height: 10px;
  width: 10px;
  opacity: 1;

  &.is-active {
    background: var(--color-dark);
    transform: scale(1);
  }

  &:focus {
    outline: unset;
  }

  &--gold {
    border-color: var(--color-gold);
  }

  &--gold.is-active {
    background: var(--color-gold);
  }
}
